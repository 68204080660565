
::selection {
    color: none;
    background: none;
}
body,html {
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    font-family: 'Komu A';
   
}
* {
    box-sizing: border-box;
    
}
@media (max-width:800px){
    body{
        background: black !important;
    }
}

@font-face {
    font-family: Komu A;
    src: url("./Assets/Komu-A.ttf") format("truetype");
    font-display: block;
}