@media (max-width:800px){
    .container{
        width: 100%;
        height: fit-content;
        background: black;
        color: white;
        display: flex;
        justify-content: center;
        
        /* border: 2px solid white; */
        font-family: 'Montserrat', sans-serif;
        font-size: 3vw;
        text-align: justify;
        font-weight: 500;
        padding-bottom: 15vw;
    }
    
    .mainContainer{
        margin-top: 100px;
        width: 83%;
        height: fit-content;
        /* border: 2px solid white; */
        display: flex;
        flex-direction: column;margin-bottom: 3vw;
    }
    .mainTitle{
        font-family: 'Komu A';
        color: #FFDC6D;
        font-size: 13vw;
        padding-bottom: 3vw;
    }
    .mainTitle span{
        color: white;
    }
    .subTitle{
        
        font-family: 'Montserrat', sans-serif;
        font-size: 5vw;
        font-weight: 700;
        padding-top: 2vw;
        padding-bottom: 2vw;
        color: #FFDC6D;
        text-align: left;
    }
    ul{
        margin: 0px;
        padding-left: 3vw;
        padding-bottom: 1vw;
    }
    li{
        margin: 1vw 0px 1vw 0px;
    }
    .customInnerPadding{
        padding-left: 8vw;
    }
  
}



/* this is desktop */

@media (min-width:800px){
    .container{
        width: 100%;
        height: fit-content;
        background: black;
        color: white;
        display: flex;
        justify-content: center;
        
        /* border: 2px solid white; */
        font-family: 'Montserrat', sans-serif;
        font-size: 1.7vw;
        text-align: justify;
        font-weight: 500;
        padding-bottom: 4.5vw;
    }
  

    .mainContainer{
        margin-top: 150px;
        width: 83%;
        height: fit-content;
        /* border: 2px solid white; */
        display: flex;
        flex-direction: column;margin-bottom: 3vw;
    }
    .mainTitle{
        font-family: 'Komu A';
        color: #FFDC6D;
        font-size: 6vw;
        padding-top: 1vw;
        padding-bottom: 3vw;
    }
    .mainTitle span{
        color: white;
    }
    .subTitle{
        
        font-family: 'Montserrat', sans-serif;
        font-size: 2.5vw;
        font-weight: 700;
        padding-top: 3vw;
        padding-bottom: 3vw;
        color: #FFDC6D;
    }
    ul{
        margin: 0px;
    }
    li{
        margin: 1vw 0px 1vw 0px;
    }
   
}

