.cardContainer{
  position: relative;
    width: 22%;
    height: 90%;
    /* background: darkcyan; */
    /* border: 2px solid wheat; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* transition: all 1s ease; */
}

.dataCardTitle{
  position: absolute;
  /* border: 2px solid greenyellow; */
  background: black;
  color: white;
  width: 78%;
  height: 15%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 2vw;
}

.dataCardBg{
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  /* border: 2px solid green; */
}

.dataCardBg img{
  width: 100%;
  height: 100%;
}

@media (max-width: 800px) {
 
  .cardContainer{
    /* background: rgb(24, 139, 215); */
    margin: 35px;
    width: 93%;
    height: 90vw;
    max-width: 530px;
    min-height: 410px;
    
  }
  .Para{
   
    /* background-color: aqua; */
    
    font-size: clamp(15px,4vw, 20px) !important;
    
    
  }
  
  .dataCardTitle{
    position: absolute;
    /* border: 2px solid greenyellow; */
    background: black;
    color: white;
    width: 78%;
    height: 15%;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 4vw;
  }
  .Heading div{
    margin-left: 8px;
    font-size: 8vw !important;
    font-weight: 600;
    
  }
}



  .Heading div{
    margin-left: 8px;
    font-size: 2vw ;
    font-weight: 600;
    
  }




.showModal{
    
    /* transition: baground-color 1s ease ease-in-out; */
    width: 100%;
    height: 102%;
    border: 2px solid rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.985);
    
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-family: 'Montserrat', sans-serif;
} 

.Heading{
    width: 100%;
    height: 20%;
    /* background: gold; */
    /* border: 2px solid white; */
    color: white;
    display: flex;
    align-items: center;
    
}


.Para{
    width: 100%;
    height: 70%;
    /* background: gold; */
    /* border: 2px solid white; */
/*    
    padding-left: 3vw;
    padding-right: 3vw; */
    color: white;
    /* text-align: justify; */
    text-justify: inter-word;
    font-size: clamp(8px, 1vw, 20px)
    
}

.transition{
    
    -webkit-transition: all 5s ease;
  transition: all 5s ease;
}

.MyClassEnter{
    opacity: 0;
  }

  .MyClassEnterActive {
    
    transition: opacity 400ms;
    opacity:0.97 ;        
  }
  .MyClassEnterDone{
    opacity: 0.97;
  }


  .MyClassExit {
    opacity: 0.97;
  }
  .MyClassExitActive {
    opacity: 0;
    transition: opacity 400ms;
  } 
  .MyClassExitDone {
    opacity: 0;
    transition: opacity 400ms;
  } 