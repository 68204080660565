
.teamContainer{
    /* border: 2px solid wheat; */
    transition: all ease-in 500ms;
    cursor: pointer;
}

.teamImage img{
    transition: all ease-in 300ms;
}




@media (max-width:800px){
    .teamContainer:hover .teamImage img{
        /* border: 2px solid green; */
       transform: scale(1.1) rotate(10deg);
    }
    .teamContainer:active .teamImage img{
        /* border: 2px solid green; */
       transform: scale(1.1) rotate(370deg)  ;
    }
    
    .mainContainer{
        width: 100%;
        height: fit-content;
        background: black;
        color: white;
        /* padding-top: 20vw; */
        display: flex;
        
        justify-content: center;
        padding-bottom: 15vw;
    }
    
    .container{
        /* border: 2px solid wheat; */
        width: 83%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-top: 95px;
    }
    .mainTitle{
        font-family: 'Komu A';
        font-size: 13vw;
        /* border: 2px solid wheat; */
        margin-bottom: 13vw;
    }
    
    .mainTitle span{
        color: #FFDC6D;
    }
    
    .teamContainer{
        width: 100%;
        height: fit-content;
        /* border: 2px solid wheat; */
        display: flex;
        flex-direction: column;
    }
    
    .teamImage{
        /* border: 2px solid wheat; */
        height: fit-content;
        width: fit-content;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 16px;
    }
    .teamImage img{
        width: 38vw;
        height: 38vw;
        padding: 2px;
        border: 2px solid white;
    }
    .teamData{
        /* border: 2px solid wheat; */
        height: 100%;
        width: 100%;
        display: flex;
        
        /* justify-content: center; */
    }
    .dataContainer{
        width: 100%;
        height: 48vw;
        /* border: 2px solid wheat; */
        font-size: 3.3vw;
    }
    .subTitle{
        margin-top: 2vw;
        font-family: 'Montserrat', sans-serif;
        color:#FFDC6D;
        margin-bottom: 1vw;
        font-weight: 700;
    }
    .description{
        font-family: 'Montserrat', sans-serif;
        text-align: justify;
    }
}

@media (min-width:800px){
    .teamContainer:hover .teamImage img{
        /* border: 2px solid green; */
       transform: scale(1.2) rotate(10deg);
    }
    .teamContainer:active .teamImage img{
        /* border: 2px solid green; */
       transform: scale(1.2) rotate(370deg) ;
       
    }
    
    .mainContainer{
        width: 100%;
        height: fit-content;
        background: black;
        color: white;
        /* padding-top: 20vw; */
        display: flex;
        
        justify-content: center;
        padding-bottom: 5vw;
    }
    
    .container{
        /* border: 2px solid wheat; */
        width: 83%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        margin-top: 130px;
    }
    .mainTitle{
        font-family: 'Komu A';
        font-size: 6vw;
        /* border: 2px solid wheat; */
        margin-bottom: 3vw;
    }
    
    .mainTitle span{
        color: #FFDC6D;
    }
    
    .teamContainer{
        width: 100%;
        height: 20vw;
        /* border: 2px solid wheat; */
        display: flex;
        /* flex-direction: column; */
    }
    
    .teamImage{
        /* border: 2px solid wheat; */
        height: 100%;
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .teamImage img{
        width: 12vw;
        height: 12vw;
        padding: 2px;
        border: 2px solid white;
    }
    .teamData{
        /* border: 2px solid wheat; */
        height: 100%;
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dataContainer{
        width: 90%;
        height: 12vw;
        /* border: 2px solid wheat; */
        font-size: 1.3vw;
    }
    .subTitle{
        font-family: 'Montserrat', sans-serif;
        color:#FFDC6D;
        margin-bottom: 1vw;
        font-weight: 700;
    }
    .description{
        font-family: 'Montserrat', sans-serif;
    }
}


.fakeContainer{
    background-color: black;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes blink{
    0%{
        opacity: 0;
        transform: scale(0.95);
    }
    50%{
        opacity: 1;
        transform: scale(1);
    }
    100%{
        opacity: 0;
        transform: scale(0.95);
    }
}
.Loading{

    width: 14vw;
    height: 18vw;
    /* border: 2px solid wheat; */
}
.Loading img{
    width: 100%;
    height: 100%;
    animation: blink 600ms infinite ease-in-out reverse;

}
