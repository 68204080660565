.container{
    
    position: absolute;
    right: 30px;
    top:8px;   
    /* background: goldenrod; */
    /* align-items: center; */
    z-index: 500;
    
}
.closeButton{
  
    /* display: inline-block; */
   
    cursor: pointer;
    color: white;
  
}
.mainContainer{
  width: 76vw;
  height: 30.5vw;
  background-color: #D81330;
  /* border: 2px solid red; */
  position: absolute;
  cursor: pointer;
}

.closeModal{
  position: absolute;
  /* border: 2px solid black; */
  
  
}

.imagee{
  position: absolute;
  /* border: 2px solid rgb(38, 228, 0); */
  width: 100%;
  height: 100%;
  
}

.imageText{
  display: flex;
  align-items: center;
  padding: 2vw;
  position: absolute;
  bottom: 7.5%;
  right: 2.5%;
  background: black ;
  width: 95%;
  height: 55%;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.6vw;
  text-align: justify;
  text-justify: inter-word;
}

.imagee img{
  width: 100%;
  height: 100%;
}