@keyframes shake {
    /* 0% { transform:  rotate(0deg); } */
    0% { transform:  rotate(0deg); }
    50% { transform:  rotate(5deg); }
    
    100% { transform:  rotate(0deg); }
    
  }
.temp{
    transform: translateY(-1000px);
    opacity: 0;
}
.illustration{
    transition: all ease-in-out 1000ms;
}
.illustration img{
    transition: all ease-in-out 500ms;
    animation: shake 1000ms infinite;
}

@media (min-width:800px){
    .container{
        width: 100%;
        height: fit-content;
        background: rgb(0, 0, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        overflow: hidden;
    }
    
    .mainContainer{
        margin-top: clamp(15px,14.5vw,125px);
        width: 84%;
        height: fit-content;
        /* border: 2px solid wheat; */
        display: flex;
        flex-direction: column;
    }
    .mainFormContainer{
        /* border: 2px solid wheat; */
        width: 100%;
        height: fit-content;
        display: flex;
    }
    .illustration{
        /* border: 2px solid wheat; */
        width: 60%;
        height: 100%;
        margin-right: 0vw;
    }
    .illustration img{
    margin-top: 5vw;
    }
    .mainContainer div{
        /* border: 2px solid wheat; */
    }
    
    .title{
        font-family: 'Komu A';
        font-size: 6vw;
    }
    .title span{
        color: #FFDC6D;
    }
    
    
    .subText div{
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    
    .subHead{
        
        font-size: 2.5vw;
        font-weight: 800;
        color: #FFDC6D;
        margin-top: 2vw;
        margin-bottom: 2vw;
    }
    .subPara1{
        
        font-size: 1.5vw;
        margin-bottom: 2vw;
    }
    .subPara2{
        
        margin-left: 5vw;
        font-size: 1.5vw;
        margin-bottom: 2vw;
    }
    .subPara3{
        
        
        font-size: 1.5vw;
        margin-bottom: 2vw;
    }
    
    .formContainer{
        font-family: 'Montserrat', sans-serif;
        height: fit-content;
        width: 45%;
        margin-bottom: 8vw;
        /* border: 2px solid wheat; */
    }
    .mainForm{
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
    }
    .mainForm input{
        background: black;
        border: 1px solid white;
        height: 3.2vw;
        border-radius: 6px;
        margin-bottom: 15px;
        color: white;
        font-size: 1.5vw;
        font-family: 'Montserrat', sans-serif;
    }
    .mainForm label{
        margin-bottom: 5px;
        font-size:1.15vw ;
    }
    .message{
        background: black;
        color: white;
        height: 20vw !important;
        overflow: auto;
        border: 1px solid white;
        border-radius: 5px;
        font-size: 1.5vw;
        font-family: 'Montserrat', sans-serif;
    }
    .message::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
     .message::-webkit-scrollbar-track {
        background: #000000; 
      }
       
      /* Handle */
    .message::-webkit-scrollbar-thumb {
        background: #888; 
      }
      
      /* Handle on hover */
    .message::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
    


}


















@media (max-width:800px){
    .container{
        width: 100%;
        height: fit-content;
        background: rgb(0, 0, 0);
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        overflow: hidden;
    }
    
    .mainContainer{
        margin-top: clamp(25px,24.5vw,125px);
        width: 84%;
        height: fit-content;
        /* border: 2px solid wheat; */
        display: flex;
        flex-direction: column;
    }
    
    .mainContainer div{
        /* border: 2px solid wheat; */
    }
    
    .title{
        font-family: 'Komu A';
        font-size: 13vw;
    }
    .title span{
        color: #FFDC6D;
    }
    
    
    .subText div{
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
    }
    
    .subHead{
        
        font-size: 5vw;
        font-weight: 800;
        color: #FFDC6D;
        margin-top: 2vw;
        margin-bottom: 3vw;
    }
    .subPara1{
        
        font-size: 3.5vw;
        margin-bottom: 3vw;
    }
    .subPara2{
        
        margin-left: 5vw;
        font-size: 3.5vw;
        margin-bottom: 3vw;
    }
    .subPara3{
        
        
        font-size: 3.5vw;
        margin-bottom: 2vw;
    }
    
    .formContainer{
        font-family: 'Montserrat', sans-serif;
        height: fit-content;
        width: 100%;
        margin-bottom: 8vw;
    }
    .mainForm{
        display: flex;
        flex-direction: column;
        font-family: 'Montserrat', sans-serif;
    }
    .mainForm input{
        background: black;
        border: 1px solid white;
        height: 9vw;
        border-radius: 3px;
        margin-bottom: 15px;
        color: white;
        font-size: 4.5vw;
        font-family: 'Montserrat', sans-serif;
    }
    .mainForm label{
        margin-bottom: 5px;
        font-size:3vw ;
    }
    .message{
        background: black;
        color: white;
        height: 35vw !important;
        overflow: auto;
        border: 1px solid white;
        border-radius: 3px;
        font-size: 4.5vw;
        font-family: 'Montserrat', sans-serif;
    }
    .message::-webkit-scrollbar {
        width: 10px;
      }
      
      /* Track */
     .message::-webkit-scrollbar-track {
        background: #000000; 
      }
       
      /* Handle */
    .message::-webkit-scrollbar-thumb {
        background: #888; 
      }
      
      /* Handle on hover */
    .message::-webkit-scrollbar-thumb:hover {
        background: #555; 
      }
    
    .illustration{
        padding-bottom: 14vw;
    }
    

}


