
@media (max-width:800px){
    .title{
      margin-top:4vw;
      margin-bottom:2vw;
      font-family: 'Komu A';
      font-size: 13vw;
      position: relative;
      left: -20vw;
    }
    .title span{
      color: #FFDC6D;
    }
     .containerForClient{
       width: 100%;
      
       
       align-items: center;
       height: fit-content;
       display: flex;
       flex-direction: column;
       
       
       
       /* scroll-behavior: smooth; */
     
     }
     
 
   .bodyForClient{
     color: white;
     height: fit-content;
     width:100%;
     background: rgb(0, 0, 0);
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     /* border: 2px solid wheat; */
     padding-top: 20vw;
    
   } 
     .containerForClient{
       width: 100%;
      
       
       align-items: center;
       height: fit-content;
       display: flex;
       flex-direction: column;
       
       margin-top: 6vw;
       margin-bottom: 6vw;
       
       /* scroll-behavior: smooth; */
     
     }
     
 
 
     .contentForClient{
       width: 100%;
       min-width: 80%;
       /* max-height: vw; */
       height:30vw;
       margin: 2.5vw;
       background: #D81330;
       /* border: 1px solid rgb(255, 255, 255); */
       /* border-style: dashed; */
       display: flex;
       align-items: center;
       justify-content: center;
     
     } 
 
     .contentForClient img{
      width: 80%;
      /* border: 2px solid black; */
      height: 130%;
      transform: scale(0.7);
    }
    .arrow1{
      display: none;
    }
    .arrow2{
      display: none;
    }
 
 }
 
 @media (min-width :800px){
   .contentForClient{
     width: 33.33%;
     min-width: 33.33%;
     max-height: 25vw;
     height:25vw;
    
     background:#D81330;
     display: flex;
     align-items: center;
     justify-content: center;
     
   }
   

   
   .contentForClient img{
     width: 100%;
     height: 70%;
     transform: scale(0.6);
     
   }
   
   .title{
     width: 100%;
     height: fit-content;
    
     /* border: 2px solid white; */
   }
   .title div{
    width: 70%;
    height: fit-content;
    margin-left: 10.58%;
    /* border: 2px solid white; */
    font-family: 'Komu A';
    font-size: 8vw;
    margin-top: 5vw;
    margin-bottom: 5vw;
    
   }

   .title div span{
     color: #FFDC6D ;
   }

   .bodyForClient{
     color: white;
     height: fit-content;
     width:100%;
     background:rgb(0, 0, 0);
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: space-evenly;
     position: relative;
     padding-bottom: 2vw;
     /* display: flex;
     align-items: center;
     justify-content: center;*/
     /* border: 2px solid wheat; */
     
 }
      .arrow1{
        position: absolute;
        width: 7vw;
        height: 7vw;
        /* border: 2px solid wheat; */
        z-index: 500;
        display: flex;
        align-items: center;
        background: #D81330;
        cursor: pointer;
      }
      .arrow2{
      position: absolute;
      right: 0px;
      width: 7vw;
      height: 7vw;
      /* border: 2px solid wheat; */
      z-index: 500;
      display: flex;
      background: #D81330;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      }
      .arrow1 img{
          height: 70%;
          width: 70%;
          transform: scaleX(-1);
          filter: opacity(0.6);
          
      }
      .arrow2 img{
        height: 70%;
        width: 70%;
        filter: opacity(0.6);
        
      }
     .containerForClient{
       width: 100%;
       overflow: scroll;
       overflow-y: hidden;
       /* align-items: center;
       justify-content: center; */
       align-items: center;
       height: 220px;
       display: flex;
       background: black;
       /* border: 2px solid wheat; */
       
       scroll-behavior: smooth;
       scrollbar-width: none;
       
       /* touch-action: cross-slide-y; */
     
     }
   
     
     .containerForClient::-webkit-scrollbar {
       background: transparent;
       
     }
 
   
 
 
   
 
     


 
 }
 
 
 
 