@media (max-width:800px){

    .container{
        /* border: 2px solid wheat; */
        display: flex;
        width: 100%;
        align-items: center;
        
    }

    .button{
        display: block;
        text-decoration: none;
        width: 20vw;
        height: fit-content;
        padding: 10px;
        margin-top: 15px;
        
        background: #D81330;
        color: white;
        border: none;
        border-radius: 2px;
        font-family: 'Montserrat', sans-serif;
        font-size: clamp(8px,3vw,15px);
        font-weight: 500;
        /* margin-bottom: 10vw; */
        
    }
    .button:hover{
        cursor: pointer;
        background: #c30e2a;
    
    }
    .lds_ellipsis {
        display: inline-block;
        position: relative;
        width: 1vw;
        height: 1vw;
      }
      .lds_ellipsis div {
        position: absolute;
        top: -3vw;
        width: 2vw;
        height: 2vw;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .lds_ellipsis div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      .lds_ellipsis div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds_ellipsis div:nth-child(3) {
        left: 24px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds_ellipsis div:nth-child(4) {
        left: 42px;
        animation: lds-ellipsis3 0.6s infinite;
      }
      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(18px, 0);
        }
      }
}

@media (min-width:800px){
    .container{
        /* border: 2px solid wheat; */
        display: flex;
        width: 100%;
        align-items: center;
        
    }
    .button{
        display: block;
        text-decoration: none;
        width: 7vw;
        height: 3vw;
        margin-top: 15px;
        
        background: #D81330;
        color: white;
        border: none;
        border-radius: 2px;
        font-family: 'Montserrat', sans-serif;
        font-size: clamp(8px,1vw,15px);
        font-weight: 500;
        
    }
    .button:hover{
        cursor: pointer;
        background: #c30e2a;
    
    }
    .lds_ellipsis {
        display: inline-block;
        position: relative;
        width: 1vw;
        height: 1vw;
      }
      .lds_ellipsis div {
        position: absolute;
        top: 1vw;
        width: 0.8vw;
        height: 0.8vw;
        border-radius: 50%;
        background: #fff;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);
      }
      .lds_ellipsis div:nth-child(1) {
        left: 6px;
        animation: lds-ellipsis1 0.6s infinite;
      }
      .lds_ellipsis div:nth-child(2) {
        left: 6px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds_ellipsis div:nth-child(3) {
        left: 24px;
        animation: lds-ellipsis2 0.6s infinite;
      }
      .lds_ellipsis div:nth-child(4) {
        left: 42px;
        animation: lds-ellipsis3 0.6s infinite;
      }
      @keyframes lds-ellipsis1 {
        0% {
          transform: scale(0);
        }
        100% {
          transform: scale(1);
        }
      }
      @keyframes lds-ellipsis3 {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(0);
        }
      }
      @keyframes lds-ellipsis2 {
        0% {
          transform: translate(0, 0);
        }
        100% {
          transform: translate(18px, 0);
        }
      }
}



/* ////////////////////////////////////////////////////////////////////// */
/* .lds_ellipsis {
    display: inline-block;
    position: relative;
    width: 1vw;
    height: 1vw;
  }
  .lds_ellipsis div {
    position: absolute;
    top: 1vw;
    width: 2vw;
    height: 2vw;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds_ellipsis div:nth-child(1) {
    left: 6px;
    animation: lds-ellipsis1 0.6s infinite;
  }
  .lds_ellipsis div:nth-child(2) {
    left: 6px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds_ellipsis div:nth-child(3) {
    left: 24px;
    animation: lds-ellipsis2 0.6s infinite;
  }
  .lds_ellipsis div:nth-child(4) {
    left: 42px;
    animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }
  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(18px, 0);
    }
  }
   */