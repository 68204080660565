


.temp{
  transform: translateY(7vw);
  opacity: 0;
}

.description{
  width: 100%;
  height: 22vw;
  /* border: 2px solid wheat; */
  position: absolute;
  bottom: 0px;
  color: white;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  background: rgba(0, 0, 0, 0.75);
  text-align: justify;
  padding-left: 4vw;
  padding-right: 4vw;
  align-items: center;
  font-size: 4vw;
  font-weight: 600;
  /* padding-top: 3vw; */
  /* border-radius:35% 35% 0% 0% ; */
  transition: all ease-in 500ms;
 

}


.slider {
    /* max-width: 100rem; */
    width: 95%;
    height: 100%;
    max-height: 900px;
    margin: 0 auto;
    position: absolute;
    /* border: 2px solid wheat; */
    /* IN THE END */
    overflow: hidden;
  }
  .slider-modal-button{
    width: 95%;
    height: 0px; /*one pixel height to view it */
    opacity: 0.7;
    background: rgb(0, 0, 0);
    position: absolute;
  }

  .slider-modal-circle{
    width: 95%;
    height: 1px;
    display: flex;
    
    justify-content: center;
    background: transparent;
    position: relative;
    top:42%
  }
  
  
  .slide {
    
    position: absolute;
    top: 0;
    width: 100%;
    height: 90%;
    /* max-height: 500px; */
    background:#D81330 ;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
    /* border: 2px solid wheat; */
  
    /* THIS creates the animation! */
    transition: transform 1s;
  }
  .slide img{
    width: 55vw;
    height: 55vw;
    position: relative;
    /* border: 2px solid black; */
  }

  .title{
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: 500;
    font-size: 8vw;
    position: relative;
    top:2%;
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* border: 2px solid black; */
  }
  /* .colour1{
    background: rebeccapurple;
  }
  .colour2{
    background: goldenrod;
  }
  .colour3{
    background: red;
  }
  .colour4{
    background: peru;
  } */
  
  /* .slide > img {
    Only for images that have different size than slide 
    width: 100%;
    height: 100%;
    z-index: 500;
    object-fit: fill
  } */
  
  .slider__btn {
    
    position: absolute;
    /* top: 80%; */
    top: -5vw;
    z-index: 1000;
  
    /* border: 2px solid wheat; */
    /* background: rgba(255, 255, 255, 0.7); */
    font-family: inherit;
    color: #333;
    /* border-radius: 50%; */
    height: 20vw;
    width: 10vw;
    font-size:1;
    cursor: pointer;
  }

  .slider__btn img{
    width: 100%;
    height: 100%;
  }


  .slider__btn--left {
    left: 9%;
    transform: translate(-50%, -50%);
  }
  
  .slider__btn--right {
    right: 9%;
    transform: translate(50%, -50%);
  }


  .circleContainer{
    width: 100px;
    height: 20px;
    /* background: black; */
    position: relative;
    top: -7vh;
    
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
  
  }
  
  
  .circleIndicator{
    width: 5px;
    height: 5px;
    background: white;
    opacity: 0.5;
    border-radius: 50%;
    
  }
  
  .circleTransform{
    transition: all 500ms ease;
    /* transform: scale(1.5); */
    opacity: 1;
  }
/* .transform1{
  transform: scale(0.8);
}
.transform2{
  transform: scale(1.2);
}
.transform3{
  transform: scale(1.6);
}
.transform4{
  transform: scale(1.7);
}
.transform5{
  transform: scale(1.9);
} */


