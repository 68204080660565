@media (max-width:800px){
    .container{
        width: 100%;
        height: fit-content;
        background: black;
        color: white;
        display: flex;
        justify-content: center;
        
        /* border: 2px solid white; */
    }
    
    .mainContainer{
        margin-top: 100px;
        width: 83%;
        height: fit-content;
        /* border: 2px solid white; */
        display: flex;
        flex-direction: column;margin-bottom: 3vw;
    }
    .title{
        font-family: 'Komu A';
        font-size: 13vw;
        padding-bottom: 3vw;
    }
    .title span{
        color: #FFDC6D;
    }
    .text{
        font-family: 'Montserrat', sans-serif;
        font-size: 3vw;
        font-weight: 500;
        text-align: justify;
    }
    .subTitle{
        color: #FFDC6D;
        font-family: 'Montserrat', sans-serif;
        font-weight: 800;
        font-size: 5vw;
        margin-bottom: 2vw;
    }
    .subText{
        color: #FFDC6D;
        font-family: 'Montserrat', sans-serif;
        font-size: 3vw;
        font-weight: 500;
    }
    .right div img{
        width: 100%;
        height: 100%;
        max-height: 300px;
    }
    .relative{
        top:15px;
        padding-bottom: 15vw;
        transition: all ease 800ms;
    }
}

@media (min-width:800px){
    .container{
        width: 100%;
        height: fit-content;
        background: black;
        color: white;
        display: flex;
        justify-content: center;
        
        /* border: 2px solid white; */
    }
    .box{
        /* border:  2px solid wheat; */
        width: 100%;
        margin-left: 150;
        margin-right: 150;

    }
    .relative {
        height: 35vw;
        width:  fit-content;
        margin: auto;
        transition: all ease 800ms;
        /* border:  2px solid wheat; */
    }
    .relative img{
        width: 100%;
        height: 100%;
        /* border:  2px solid wheat; */
        
    }
    
    .left{
        height: fit-content;
        width: 60%;
        /* border: 2px solid wheat; */
    }
    .right{
        height: fit-content;
        width: 40%;
        /* border: 2px solid wheat; */
        display: flex;
        justify-content: flex-end;
    }

    .right div{
        width: fit-content;
        height: fit-content;
        /* border: 2px solid wheat; */
    }
    .right div img{
        width: 32vw;
        height: 32vw;
        max-height: 500px;
        /* border: 2px solid wheat; */
    }

    .mainContainer{
        margin-top: 150px;
        width: 83%;
        height: fit-content;
        /* border: 2px solid white; */
        display: flex;
        flex-direction: column;margin-bottom: 3vw;
    }
    .title{
        font-family: 'Komu A';
        font-size: 6vw;
        padding-bottom: 3vw;
    }
    .title span{
        color: #FFDC6D;
    }
    .text{
        font-family: 'Montserrat', sans-serif;
        font-size: 1.7vw;
        text-align: justify;
        font-weight: 500;
    }
    .subTitle{
        color: #FFDC6D;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 2.5vw;
        margin-bottom: 2vw;
    }
    .subText{
        color: #FFDC6D;
        font-family: 'Montserrat', sans-serif;
        font-size: 1.7vw;
        font-weight: 500;
    }
}

.temp{
    opacity: 0;
    transform: translateY(200px) ;
    border: 2px solid wheat;

}