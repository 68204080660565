
@media (max-width:800px){
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: #100e10;
    opacity: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 90000000000;
    overflow: hidden;
    
  }

  .closeModal{
      
      /* background-color: aqua; */
      /* border: 2px solid white; */
      width: 100%;
      height: 10%;
  }
  .options{
    /* background-color: bisque; */
    /* border: 2px solid greenyellow; */
    width: 100%;
    height: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
}
.optionsStyle{
  /* background-color: bisque; */
  /* border: 2px solid wheat; */
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 80%;
  
}

.textStyle{
  height:100%;
  font-size: 8vw;
  font-weight: 900;
  font-family: cursive;
  color: aliceblue;
  font-family: 'Komu A';
  font-weight: 300;
 
  /* animation: TextAnimationSpan  400ms ease-out forwards; */

  /* animation: animateSpan 400ms ease-out forwards;
  animation-direction: reverse; */

}
.textStyle a{

  text-decoration: none;
 
}

/* .textStyle span sup{
  font-size: 15px;
 
} */

.textStyle  span a:hover{
  display: inline-block;
  /* background: green; */
  /* transition: all 100ms linear; */
  
  /* left: 10px;  */
  /* translate:r̥ */
  /* transform: translateX(15px); */
  animation: animateSpan 200ms ease-out forwards ;

}


@keyframes animateSpan {
  from{
    position: relative; 
    left: 0px;
  }
  to{
    position: relative; 
    color: rgb(132, 3, 3); 
  
    left: 10px;
  }
  
}

@keyframes moveImage{
  from{
    position: relative;
    top:0px
  
  } to{
    position: relative;
    top:-4px;
  }
}

@keyframes reverseAnimateSpan {
  0%{
    position: relative; 
    
  }
  100%{
    position: relative; 
    left: -15px;
  }
  
}


.wrapper{
  /* border: 2px solid wheat; */
    display: flex;
    flex-direction: column;
    width: 85%;
    height: 100%;
    justify-content: space-evenly;
    
    
}
.contactUs{
   /* background-color: rgb(255, 66, 66); */
    /* border: 2px solid white; */
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
   

    
}
.contactUs_text{
  font-family: 'Montserrat', sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
}
.contactUs_text_title{
  font-weight: 500;
  margin-bottom: 8px;
}
.contactUs_text_subTitle{
  /* border: 2px solid wheat; */
  display: flex;
  width: 85%;
  color: rgba(240, 248, 255, 0.617);
  font-size: 14px;
}

.contactUs_image{
  /* border: 2px solid wheat; */
  padding-top: 5%;
  display: flex;
  width: 60vw;
  height: 40vw;
}

.contactUs_image div{
  width: 15vw;
  height: 15vw;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.contactUs_image div img{
  background-color: #100e10;
 
  width: 50%;
  height: 50%;
  cursor: pointer;
}

.contactUs_image div img:hover{
  transition: 300ms ease ;
  animation: moveImage 100ms ease forwards;
  
    
}


.closeButton{
    margin-right: 15px;
    cursor: pointer;
    
}

.closeModal{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    transition: 0.4s;
  }

  .bar1{
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  .bar2{
      opacity: 0;
  }
  .bar3{
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }


/* 

  .MyClassEnterDone {
    transition: opacity 1000ms;
   opacity:0.9 ;
   
  } */

  .MyClassEnter{
    opacity: 0;
  }

  .MyClassEnterActive {
    
    transition: opacity 500ms;
    opacity:0.999 ;        
  }
  .MyClassEnterDone{
    opacity: 0.999;
  }


  .MyClassExit {
    opacity: 0.995;
  }
  .MyClassExitActive {
    opacity: 0;
    transition: opacity 500ms;
  } 
  .MyClassExitDone {
    opacity: 0;
    transition: opacity 500ms;
  } 

}















@media (min-width:800px){
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: auto;
    background: #100e10;
    opacity: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    z-index: 90000000000;
    
  }

  .closeModal{
      
      /* background-color: aqua; */
      /* border: 2px solid white; */
      width: 100%;
      height: 10%;
  }
  .options{
    /* background-color: bisque; */
    /* border: 2px solid greenyellow; */
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}
.optionsStyle{
  /* background-color: bisque; */
  /* border: 2px solid wheat; */
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  
}

.textStyle{
  height:100%;
  font-size: 45px;
  font-weight: 900;
  font-family: cursive;
  color: aliceblue;
  font-family: 'Komu A';
  font-weight: 300;
 
  /* animation: TextAnimationSpan  400ms ease-out forwards; */

  /* animation: animateSpan 400ms ease-out forwards;
  animation-direction: reverse; */

}
.textStyle a{

  text-decoration: none;
 
}

/* .textStyle span sup{
  font-size: 15px;
 
} */

.textStyle  span a:hover{
  display: inline-block;
  /* background: green; */
  /* transition: all 100ms linear; */
  
  /* left: 10px;  */
  /* translate:r̥ */
  /* transform: translateX(15px); */
  animation: animateSpan 200ms ease-out forwards ;

}


@keyframes animateSpan {
  from{
    position: relative; 
    left: 0px;
  }
  to{
    position: relative; 
    color: rgb(132, 3, 3); 
  
    left: 10px;
  }
  
}

@keyframes moveImage{
  from{
    position: relative;
    top:0px
  
  } to{
    position: relative;
    top:-4px;
  }
}

@keyframes reverseAnimateSpan {
  0%{
    position: relative; 
    
  }
  100%{
    position: relative; 
    left: -15px;
  }
  
}


.wrapper{
  /* border: 2px solid wheat; */
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    justify-content: space-evenly;
    
    
}
.contactUs{
   /* background-color: rgb(255, 66, 66); */
    /* border: 2px solid white; */
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
   

    
}
.contactUs_text{
  font-family: 'Montserrat', sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
}
.contactUs_text_title{
  font-weight: 600;
  margin-bottom: 8px;
}
.contactUs_text_subTitle{
  /* border: 2px solid wheat; */
  display: flex;
  width: 30%;
  color: rgba(240, 248, 255, 0.617);
  font-size: 14px;
}

.contactUs_image{
  /* border: 2px solid wheat; */
  display: flex;
  width: 30%;
  height: 30%;
}

.contactUs_image div{
  width: 4vw;
  height: 4vw;
  /* border: 2px solid blue; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.contactUs_image div img{
  background-color: #100e10;
 
  width: 50%;
  height: 50%;
  cursor: pointer;
}

.contactUs_image div img:hover{
  transition: 300ms ease ;
  animation: moveImage 100ms ease forwards;
  
    
}


.closeButton{
    margin-right: 15px;
    cursor: pointer;
    
}

.closeModal{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.bar1, .bar2, .bar3 {
    width: 35px;
    height: 5px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    transition: 0.4s;
  }

  .bar1{
    -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-9px, 6px);
  }
  .bar2{
      opacity: 0;
  }
  .bar3{
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }


/* 

  .MyClassEnterDone {
    transition: opacity 1000ms;
   opacity:0.9 ;
   
  } */

  .MyClassEnter{
    opacity: 0;
  }

  .MyClassEnterActive {
    
    transition: opacity 500ms;
    opacity:0.999 ;        
  }
  .MyClassEnterDone{
    opacity: 0.999;
  }


  .MyClassExit {
    opacity: 0.995;
  }
  .MyClassExitActive {
    opacity: 0;
    transition: opacity 500ms;
  } 
  .MyClassExitDone {
    opacity: 0;
    transition: opacity 500ms;
  } 

}