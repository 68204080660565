.container{
    transition: all ease-in 500ms ;
}
.image{
    transition: all ease-in 500ms ;
}
.imageTemp{
    opacity: 1;
    position: relative;
    transform: translateY(-10vw);
}
.temp{
    opacity: 0;
    position: relative;
    transform: translateY(100px);
}


@media (max-width:800px){
    .mainContainer{
        width: 100%;
        height: fit-content;
        padding-top: 10vw;
        /* padding-bottom: 10vw; */
        background: black;
        display: flex;
        align-items: center;
        /* border: 2px solid white; */
        
        
        
    }
    .image{
        width: 30%;
        height: 145%;
        /* border: 2px solid white; */
        position: absolute;
        bottom: -3%;
        right: 4%;
    
    }
    .image img{
        width: 100%;
        height: 100%;
        transform: scale(1.22);
    }
    .container{
        color: white;
        width: 100%;
        height: 60vw;
        background: black;
        /* border: 2px solid white; */
    }
    
    .title{
        color: white;
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        /* border: 2px solid white; */
    }
    
    .title_main{
        margin-left: 8%;
        
        width: 62%;
        height: 50%;
        /* border: 2px solid white; */
        font-family: 'Komu A';
        font-size: 12vw;
        justify-content: center;
    }
    
    .title_main span{
        color: #FFDC6D ;
    }
    
    .title_sub{
        margin-top: 2vw;
        margin-left: 8%;
        width: 62%;
        height: 50%;
        /* border: 2px solid white; */
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 2.4vw;
    }
    
    .text{
        color: white;
        width: 100%;
        height: 60%;
        background: #D81330;
        /* border: 2px solid white; */
        position: relative;
        display: flex;
        align-items: center;
        text-align: justify;
    }
    .textContainer{
        margin-left: 8%;
        width: 55%;
        height: 80%;
        color: white;
        /* border: 2px solid white; */
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 2.2vw;
    }
}

@media (min-width:800px){
    .mainContainer{
        width: 100%;
        height: 100vh;
        background: black;
        display: flex;
        align-items: center;
        /* border: 2px solid white; */
        
        
        
    }
    .image{
        width: 30%;
        height: 145%;
        /* border: 2px solid white; */
        position: absolute;
        bottom: -3%;
        right: 8%;
    
    }
    .image img{
        width: 100%;
        height: 100%;
        transform: scale(1.22);
    }
    .container{
        color: white;
        width: 100%;
        height: 34vw;
        background: black;
        /* border: 2px solid white; */
    }
    
   
   

    .title{
        color: white;
        width: 100%;
        height: 40%;
        display: flex;
        flex-direction: column;
        /* border: 2px solid white; */
    }
    
    .title_main{
        margin-left: 8%;
        width: 62%;
        height: 50%;
        margin-bottom: 1%;
        /* border: 2px solid white; */
        font-family: 'Komu A';
        font-size: 7vw;
        justify-content: center;
    }
    
    .title_main span{
        color: #FFDC6D ;
    }
    
    .title_sub{
        margin-left: 8%;
        width: 62%;
        height: 50%;
        /* border: 2px solid white; */
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 1.62vw;
    }
    
    .text{
        color: white;
        width: 100%;
        height: 60%;
        background: #D81330;
        /* border: 2px solid white; */
        position: relative;
        display: flex;
        align-items: center;
        text-align: justify;
    }
    .textContainer{
        margin-left: 8%;
        width: 50%;
        height: 80%;
        color: white;
        /* border: 2px solid white; */
        font-family: 'Montserrat', sans-serif;
        font-weight: 500;
        font-size: 1.6vw;
    }
}
