
@media (max-width: 800px){
    .footerDiv{
    padding-left: 2%;
    padding-top: 10%;
    padding-bottom: 10%;
    padding-right: 3%;
    background-color:  #d81330;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: 'Montserrat', sans-serif;
    font-size: 3vw;
    font-weight: 500;
    
}
.footerDiv div{
    /* border: 2px solid wheat; */
}
.listOfLinksMobile{
    display: flex;
    flex-direction: column;
}
.listOfLinks{
    margin-bottom: 20px;   
}
.logoImageDiv{
    height: fit-content;
    width: fit-content;
   /* border: 2px solid wheat; */
    margin-right: 5vw;
    margin-left: 5vw;
}
.logoImageDiv img{
    width: 16vw;
    height: 16vw;
    border-radius: 2vw;
}
a:link{
    color: white;
    text-decoration: none;
}
a:visited{
    color: white;
    text-decoration: none;

}
img{
    text-align: center;
    flex-direction: column;
}
.logoImage{
    border-radius: 5%;
    height: 100%;
    width: 100%;
}
.listOfOtherLinks p{
    margin: 0;
    color: white
    
}
.listOfSocialMedia{
    display: flex;
    flex-direction: row;
    width: 40%;
    min-width: 100px;
    margin-right: 7%;
    margin-top: 2vw;
    margin-left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
             
}



.spaceBetweenLinks{
    height: 5px;
}
.socialMediaIcons{
    width: fit-content;
    margin-bottom: clamp(5px, 5vw, 20px);
}
.socialMediaIcons img{
    height: 4vw;
    width:  4vw ;
   
}
}

@media  (min-width: 800px){
    .socialMediaIcons img{
        height: 2vw;
        width:  2vw ;
       
    }

.footerDiv{
    background-color: #d81330;
    padding-top: 3vw !important;
    padding-bottom: 3vw !important;
    padding-left: 5%;
    padding-right: 5;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    display: flex;
    flex-direction: row;
}

.listOfLinks{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.listOfLinksMobile{
    margin-top: 0;
    display: flex;
    flex-direction: row;
    margin-right: 13%;
    
}
a:link{
    color: white;
    text-decoration: none;
}
a:visited{
    color: white;
    text-decoration: none;

}
.listOfOtherLinks{
    /* margin-left: ; */
    display: flex;
    flex-direction: column;
   
}
.listOfOtherLinks p{
    margin: 0;
    color: white
    
}
.listOfSocialMedia{
    display: flex;
    flex-direction: row;
    width: 25%;
                
}

.logoImage{
    border-radius: 20%;
    height: 100%;
    width: 100%;
}
.socialMediaIcons{
    width: 10%;
    margin: 5px;
}
.socialMediaIcons img:hover{
    cursor: pointer;
}
.logoImageDiv{
    height: fit-content;
    width: fit-content;
    margin-right: 7%;
    margin-left: 5%
}
.logoImageDiv img{
    
    width: 6vw;
    height: 6vw;
}
.listOfLinksMobile{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 50%;
}
.spaceBetweenLinks{
    height: 10px;
}
}

.copyRight{
    color: white;

    width: 100%;
    height: 50px;
    background: black;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Open Sans', sans-serif;
}
