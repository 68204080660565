
.temp1{
  transform: translateX(300px);
  opacity: 0;
}
.temp2{
  transform: translateX(-100px);
  opacity: 0;
}

.temp3{
  transform: translatey(100px);
  opacity: 0
}

.approach{
  /* border: 2px solid wheat; */
  transition: all ease-in 600ms;
}

.Data {
    width: 100%;
    height: fit-content;
    background: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    /* border: 2px solid wheat; */
    overflow: hidden;
}
.approach{
  color: white;
  margin-top: 4.5vw;
  width: 78%;
  height: 20vw;
  max-height: 60%;
  /* border: 2px solid white; */
  display: flex;
}
.approach_text{
  width: 65%;
  height: 100%;
  /* border: 2px solid white; */
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.approach_text_top{
  width: 100%;
  height: fit-content;
  /* border: 2px solid white; */
  font-family: 'Komu A';
  font-size: 6.5vw;
}
.approach_text_top span{
  color: #FFDC6D;
}

.approach_text_bottom{
  width: 100%;
  height: 30%;
  /* border: 2px solid white; */
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 1.7vw;
}

.approach_image{
  width: 35%;
  height: 100%;
  /* border: 2px solid blue; */
}


.DataContainer{
    width: 82%;
    height: 26vw;
    max-height: 60%;
    /* background-color: aqua; */
    /* border: 2px solid white; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    transition: all ease-in 500ms;
  
}
@media (max-width: 800px) {
 
  .DataContainer {
    /* background: rgb(181, 126, 126); */
    margin: 0px;
    flex-direction: column;
    height: fit-content;
    /* min-height: 2000px; */

  }
  .Data{
    width: 100%;
    padding-top: 22vw;
    height:fit-content;
    flex-direction: column;
    /* border: 2px solid wheat; */
    overflow: hidden;
    
    
  }

  .approach{
    margin-top: 0px;
    /* border: 2px solid wheat; */
    flex-direction: column;
    height: fit-content;
    align-items: center;
    width: 88%;
  }
  .approach_text{
    
    /* border: 2px solid wheat; */
    width: 100%;
  }
  .approach_text_top{
    
    /* border: 2px solid wheat; */
    font-size: 13vw;
  }
  .approach_text_bottom{
    
    /* border: 2px solid wheat; */
    font-size: 3vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
  }
  .approach_image{
    width: 90%;
    height: 90%;
    /* border: 2px solid wheat; */
  }



  .gridContainer{
  display: none;
}
  

}

/* @media(max-width: 500px){
  .Data3{
    background: brown;
   
  }
 
  .sliderContainer{
    background-color: blue;
  }
} */



.Data2 {
    position: relative;
    width: 100%;
    height: 52vw; /*dont make it fit-content*/
    padding-top: 1vw;
    
    background: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom:5vw ;
    /* padding-bottom: max(15px); */
    flex-direction: column;
    /* border: 2px solid white; */
}
.data2MainTitle{
  position: absolute;
  top: 12%;
  color: white;
  width: 100%;
  height: 20%;
  /* border: 2px solid wheat; */
  display: flex;
  align-items: center;
}

.data2MainTitle div{
  width:100%;
  height:100%;
  /* border:2px solid red; */
  margin-left: 10.8%;
  font-family: 'Komu A';
  font-size: 8vw;

}
.data2MainTitle div span{
    color:#FFDC6D

}
.Data3{
  width: 100%;
  height: fit-content;
  
  
  background: rgb(0, 0, 0);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  transition: all ease-in 1000ms;
  /* border: 2px solid wheat; */
  padding-top: 22vw;
}
.sliderContainer{
  width: 95%;
  height: 120vw;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */
  position: relative;
  /* border: 2px solid green; */
}

.gridContainer{
    width: 100%;
    gap: 15px;
    height: fit-content;
    max-width: fit-content;
    max-height: fit-content;
    /* border: 4px solid green; */
    /* background-color: rgb(185, 1, 154); */
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.Data3Title{
    /* border: 2px solid white; */
    margin-left: 5vw;
    width: 92%;
    height: 12vh;
    color: white;
    font-family: 'Komu A';
    font-size: 13.5vw;
}
.Data3Title span{
    color: #FFDC6D;
}
.MyClassEnter{
    opacity: 0;
  }

  .MyClassEnterActive {
    
    /* transition: opacity 5500ms; */
    opacity:1 ;        
  }
  .MyClassEnterDone{
    opacity: 1;
  }


  .MyClassExit {
    display: none;
  }
  .MyClassExitActive {
     display: none; 
    /* opacity: 0;
    transition: opacity 500ms; */
  } 
  .MyClassExitDone {
    display: none;
  } 

  .MyClass2Enter{
    opacity: 0;
    
  }

  .MyClass2EnterActive {
    
    
    transition: opacity 500ms ease-out;
    opacity:1 ;        
  }
  .MyClass2EnterDone{
    opacity: 1;
  }


  .MyClass2Exit {
    opacity: 1;
    display: none;
  }
  .MyClass2ExitActive {
    opacity: 0;
    transition: opacity 500ms;
  } 
  .MyClass2ExitDone {
    opacity: 0;
    
  } 

  .SlideModalOverlay{
    border-radius: 8px;
    width: 95%;
    height: 90%;
    max-height: 900px;
    margin: 0 auto;
    position: absolute;
    top: 0px;
    background:  #D81330;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .SlideModalOverlay img{
    width: 80%;
    height: 70%;
    position: absolute;
    top: 10%;
    /* display: none; */
    
    /* border: 2px solid black; */
  
   
  }
  .displayNone{
    display: none;
  }
  .dataForSliderModal{
    position: absolute;
    width: 100%;
    height: 55%;
    bottom: 2%;
    color: white;
    font-family: 'Montserrat', sans-serif;
    /* border: 2px solid black; */
    text-align: justify;
    font-size: 3.5vw;
    padding: 10px;
  }
.titleForSliderModal{
  position: absolute;
    width: 100%;
    height: 18%;
    /* border: 2px solid black; */
    top: 5%;
    padding: 10px;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 6.5vw;
    font-weight: 600;
    /* border: 2px solid black; */
}

  /* //////////////////////////////////////////////////// */











