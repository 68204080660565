
  @media (max-width:800px){
    .container {
      display: inline-block;
      cursor: pointer;
    }
  
    .container img{
      filter: invert(100);
      width: 4vw;
      height: 4vw;
    }
  
  .container span{
    color: white;
    opacity: 0.9;
    font-size: 6vw;
  }
  
  
    
    .bar1, .bar2, .bar3 {
      width: 35px;
      height: 5px;
      background-color: rgb(255, 255, 255);
      margin: 6px 0;
      transition: 0.4s;
    }
    
    .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
    }
    
    .change .bar2 {opacity: 0;}
    
    .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }
  }

  @media (min-width:800px){
    .container {
      display: inline-block;
      cursor: pointer;
    }
    
    .container img{
      filter: invert(100);
      width: 1.2vw;
      height: 1.2vw;
    }
  
  .container span{
    color: white;
    opacity: 0.9;
    font-size: 2vw;
  }
  
  
    
    .bar1, .bar2, .bar3 {
      width: 35px;
      height: 5px;
      background-color: rgb(255, 255, 255);
      margin: 6px 0;
      transition: 0.4s;
    }
    
    .change .bar1 {
      -webkit-transform: rotate(-45deg) translate(-9px, 6px);
      transform: rotate(-45deg) translate(-9px, 6px);
    }
    
    .change .bar2 {opacity: 0;}
    
    .change .bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }
  }