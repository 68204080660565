
@media  (max-width:800px){
  .container{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: url("../../Assets/LandingBG/homebgMobile.svg") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: 100% 100% ; 
    /* background: black !important; */
  }

  .frontText{
    position: absolute;
    color: white;
    top:12%;
    left: 9.3%;
    width: 50vw ;
    max-width: 300px;
    height: 27vw ;
    /* border: 2px solid  wheat; */
    font-family: 'Komu A';
    display: flex;
    flex-direction: column;
    
  }
  .frontTextTop{
    width: 100%;
    display: flex;
    height: 38%;
    /* border: 2px solid white; */
  }
  .frontTextTopLeft{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 68%;
    /* border: 2px solid white; */
    font-size: clamp(2px,9vw,50px);
    justify-content: flex-end;
  
  }
  .frontTextTopRight{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 32%;
    justify-content: flex-end;
    /* border: 2px solid white; */
  }
  .frontTextTopRightContent{
    display: flex;
    align-items: flex-end;
    width: 100%;
    height: 50%;
    font-size: clamp(2px,3vw,18px);
    margin-bottom: 2px;
  
    font-family: 'Open sans';
    font-weight: 700;
  
  }
  
  
  .frontTextBottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFDC6D;
    width: 100%;
    height: 52%;
    /* border: 2px solid white; */
    font-size: clamp(2px,17vw,110px);
  }
  
  .bottomText{
    position: absolute;
    width: clamp(1vw,60vw,350px);
    height: 32vw;
    max-height: 230px;
    /* border:2px solid white; */
    bottom: 8%;
    right: 2%;
    display: flex;
    flex-direction: column;
    
  }
  .bottomTextTop{
    /* border: 2px solid white; */
    width: 100%;
    height: 40%;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-size: clamp(15px,3.5vw,25px);
    font-weight: 500;
    margin-bottom: clamp(3px,3vw,10px);
    
  
  
  }
  .bottomTextBottom{
    /* border:  2px solid white; */
    width: 100%;
    height: 60%;
    font-family: 'Komu A';
    color:#FFDC6D;
    font-size: clamp(5px,7.7vw,50px);
    
  
    
  }
  .bottomTextBottom span{
    
    color:white;
    
  }
}


































/* /////////////////////////////////////////////////////////////////////////// */


@media (min-width:800px){
  .container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    background: url("../../Assets/LandingBG/homebg.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100% ; 
    
}
.container img{
  /* border: 5px solid wheat; */
  
  /* position: absolute; */
  /* height: 100vh;
  width: 100%; */
}

/* .BgImage{
  width: 100%;
  height: 100%;
  
  background-repeat: no-repeat;
  overflow: hidden;
 

  
} */
.prakriyaMan{
  position: absolute;
  /* border: 2px solid green; */
  /* height: 400px;
  width: 60vw;
  top:120px; */
  /* margin-top: 48px;
  margin-right: 5vw;
  overflow: hidden; */
  /* width: 88vw; */
  /* max-width: 1800px; */
  /* height: 108vh;  */
  /* transform: scale(0.65); */
  /* transform: rotate(4deg); */
    /* width: 63vw;
    
    height: 64vh;
    margin-top: -95px;
    transform: rotate(2deg); */

    /* border: 2px solid white ; */
    height: 26.5vw;
    width: 50vw;
    top:16.8%;
    right: 28%;
    transform: scale(1.35) rotate(2.5deg);
    
}
.frontText{
  position: absolute;
  color: white;
  top:19%;
  left: 9.3%;
  width: 27vw;
  height: 14vw;
  /* border: 2px solid  white; */
  font-family: 'Komu A';
  display: flex;
  flex-direction: column;
  transform: scale(1.1);
}
.frontTextTop{
  width: 100%;
  display: flex;
  height: 38%;
  /* border: 2px solid white; */
}
.frontTextTopLeft{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 68%;
  /* border: 2px solid white; */
  font-size: 5.2vw;
  justify-content: flex-end;

}
.frontTextTopRight{
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 32%;
  justify-content: flex-end;
  /* border: 2px solid white; */
}
.frontTextTopRightContent{
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 50%;
  font-size: 1.5vw;
  margin-bottom: 8px;
  margin-left: 5px;
  font-family: 'Open sans';
  font-weight: 700;

}


.frontTextBottom{
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFDC6D;
  width: 100%;
  height: 52%;
  /* border: 2px solid white; */
  font-size: 10vw;
}

.bottomText{
  position: absolute;
  width: 38vw;
  height: 15vw;
  /* border:2px solid white; */
  bottom: 10%;
  right: 5%;
  display: flex;
  flex-direction: column;
}
.bottomTextTop{
  /* border: 2px solid white; */
  width: 100%;
  height: 40%;
  font-family: 'Montserrat', sans-serif;
  color: white;
  font-size: 1.7vw;
  font-weight: 500;


}
.bottomTextBottom{
  /* border:  2px solid white; */
  width: 90%;
  height: 60%;
  font-family: 'Komu A';
  color:#FFDC6D;
  font-size: 3.7vw;
  

  
}
.bottomTextBottom span{
  
  color:white;
  
}
}

